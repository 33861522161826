/*
 * @Author: xiaoxingliang
 * @Date: 2021-12-18 10:35:18
 * @LastEditTime: 2021-12-22 16:51:00
 * @LastEditors: xiaoxiangliang
 * @FilePath: /lc_factory/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import AMap from 'vue-amap'
import dataV from '@jiaminghi/data-view'
import Viser from 'viser-vue'
import '@/styles/index.scss'
import '@/icons' // icon
import VXETable from 'vxe-table'
import 'xe-utils'
import 'vxe-table/lib/style.css'
import '@/constant/iconfont.js'
import '@/router/permission.js'
// import "@/assets/icons/index.js"; // icon

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import '@/assets/icon/iconfont.css'

Vue.use(ElementUI, { locale })
Vue.use(VXETable)
Vue.config.productionTip = false

Vue.use(AMap)
Vue.use(Viser)
Vue.use(dataV)
Vue.use(Viewer)

/* ***********************以下为table自适应设备屏幕高度********************************************* */
// table的自适应
window.topsTableChangeHeight = []
const onWindowResize = () => {
  for (const item of window.topsTableChangeHeight) {
    window.requestAnimationFrame(item.func)
  }
}
// 添加window 的resize事件监听 防止项目工程中冲突
window.addEventListener('resize', onWindowResize)
/* ******************************************************************** */

window._AMapSecurityConfig = {
  securityJsCode: '1fa9be741988a72de4cd69f414868b3b'
}
// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德的key
  // key: "0ae6520eee8250056237b1cdbea62c46",
  key: 'fe7408f639ab21804eb5f76a40257ae1', //
  // 插件集合 （插件按需引入）
  // plugin: [],
  plugin: [
    'AMap.Autocomplete',
    'AMap.Geocoder',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.MoveAnimation',
    'AMap.MouseTool',
    'AMap.Geolocation',
    'AMap.CitySearch'
  ],
  uiVersion: '1.0.11',
  v: '1.4.4'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
